import { ChangeEvent } from 'react';
import { Controller, useForm } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import { styled } from '@mui/material/styles';
import Button, { ButtonProps } from '@mui/material/Button';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Country } from '../fetchCountries';

export interface BillingFormValues {
  firstName: string;
  lastName: string;
  email: string;
  mobilePhone: string;
  address: string;
  city: string;
  postalCode: string;
  country: Country | null;
}

interface CollectBillingProps {
  billingOnSubmit: (formValues: BillingFormValues) => void;
  isEditing: boolean;
  initialValues: BillingFormValues | null;
  countries: Country[];
  handleCancelClick: () => void;
}

const StyledFormContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(8),
  width: '100%'
}));

const Title = styled(Typography)(({ theme }) => ({
  variant: 'body1',
  marginBottom: theme.spacing(4),
  fontWeight: 600
}));

const InputField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  width: '100%',
  '& input::placeholder': {
    color: '#667085',
    opacity: 1
  },
  '.MuiOutlinedInput-notchedOutline': {
    border: '1px solid rgba(0, 0, 0, 0.12)'
  }
}));

const InputLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  marginBottom: theme.spacing(1)
}));

const StyledDropdown = styled(Select)(({ theme }) => ({
  marginBottom: theme.spacing(0)
}));

const StyledCountryErrorText = styled(FormHelperText)(({ theme }) => ({
  marginBottom: theme.spacing(5)
}));

const ContinueButton = styled(Button)<ButtonProps>(({ theme }) => ({
  borderRadius: theme.spacing(2),
  backgroundColor: theme.colors.base.black,
  textTransform: 'none',
  '&:hover': {
    backgroundColor: theme.colors.base.black
  },
  boxShadow: 'none',
  color: theme.colors.base.white,
  fontSize: theme.spacing(4),
  height: theme.spacing(12),
  marginBottom: theme.spacing(2),
  padding: 0,
  width: '100%'
}));

const CancelButton = styled(Button)<ButtonProps>(({ theme }) => ({
  borderRadius: theme.spacing(2),
  backgroundColor: theme.colors.base.white,
  textTransform: 'none',
  '&:hover': {
    backgroundColor: theme.colors.base.white
  },
  boxShadow: 'none',
  border: `1px solid ${theme.colors.base.black}`,
  color: theme.colors.base.black,
  fontSize: theme.spacing(4),
  height: theme.spacing(12),
  marginRight: theme.spacing(4),
  padding: 0,
  width: '100%'
}));

const ButtonFooter = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: theme.spacing(4)
}));

const CollectBilling = ({
  billingOnSubmit,
  isEditing = false,
  initialValues,
  countries,
  handleCancelClick
}: CollectBillingProps) => {
  const { t } = useTranslation();


  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm<BillingFormValues>({
    defaultValues: initialValues || {
      country: null
    }
  });

  const onHandleFormChange = (
    event: ChangeEvent,
    field: keyof BillingFormValues
  ) => {
    const inputValue = (event.target as HTMLInputElement).value;
    setValue(field, inputValue);
  };

  const handlePress = async () => {
    handleSubmit(billingOnSubmit)();
  };

  return (
    <StyledFormContainer data-testid="collect-billing">
      <Title>{t('restaurant.checkout.billingInformation')}</Title>
      <form>
        <InputLabel variant="subtitle1">
          {t('restaurant.checkout.firstName')}
        </InputLabel>
        <Controller
          name="firstName"
          control={control}
          rules={{
            required:
              t('restaurant.checkout.firstName') +
              ' ' +
              t('restaurant.checkout.isRequired')
          }}
          render={({ field }) => (
            <InputField
              {...field}
              placeholder={t('restaurant.checkout.enterFirstName')}
              fullWidth
              error={!!errors.firstName}
              onChange={(e) => onHandleFormChange(e, 'firstName')}
              helperText={errors.firstName?.message}
            />
          )}
        />
        <InputLabel variant="subtitle1">
          {t('restaurant.checkout.lastName')}
        </InputLabel>
        <Controller
          name="lastName"
          control={control}
          rules={{
            required:
              t('restaurant.checkout.lastName') +
              ' ' +
              t('restaurant.checkout.isRequired')
          }}
          render={({ field }) => (
            <InputField
              {...field}
              placeholder={t('restaurant.checkout.enterLastName')}
              fullWidth
              error={!!errors.lastName}
              onChange={(e) => onHandleFormChange(e, 'lastName')}
              helperText={errors.lastName?.message}
            />
          )}
        />
        <InputLabel variant="subtitle1">
          {t('restaurant.checkout.email')}
        </InputLabel>
        <Controller
          name="email"
          control={control}
          rules={{
            required:
              t('restaurant.checkout.email') +
              ' ' +
              t('restaurant.checkout.isRequired'),
            pattern: /^\S+@\S+$/i
          }}
          render={({ field }) => (
            <InputField
              {...field}
              placeholder={t('restaurant.checkout.enterEmail')}
              fullWidth
              error={!!errors.email}
              onChange={(e) => onHandleFormChange(e, 'email')}
              helperText={errors.email?.message}
            />
          )}
        />
        <InputLabel variant="subtitle1">
          {t('restaurant.checkout.mobilePhone')}
        </InputLabel>
        <Controller
          name="mobilePhone"
          control={control}
          rules={{
            required:
              t('restaurant.checkout.mobilePhone') +
              ' ' +
              t('restaurant.checkout.isRequired')
          }}
          render={({ field }) => (
            <InputField
              {...field}
              placeholder={t('restaurant.checkout.enterMobilePhone')}
              fullWidth
              error={!!errors.mobilePhone}
              onChange={(e) => onHandleFormChange(e, 'mobilePhone')}
              helperText={errors.mobilePhone?.message}
            />
          )}
        />
        <InputLabel variant="subtitle1">
          {t('restaurant.checkout.streetAddress')}
        </InputLabel>
        <Controller
          name="address"
          control={control}
          rules={{
            required:
              t('restaurant.checkout.streetAddress') +
              ' ' +
              t('restaurant.checkout.isRequired')
          }}
          render={({ field }) => (
            <InputField
              {...field}
              placeholder={t('restaurant.checkout.enterAddress')}
              fullWidth
              error={!!errors.address}
              onChange={(e) => onHandleFormChange(e, 'address')}
              helperText={errors.address?.message}
            />
          )}
        />
        <InputLabel variant="subtitle1">
          {t('restaurant.checkout.city')}
        </InputLabel>
        <Controller
          name="city"
          control={control}
          rules={{
            required:
              t('restaurant.checkout.city') +
              ' ' +
              t('restaurant.checkout.isRequired')
          }}
          render={({ field }) => (
            <InputField
              {...field}
              placeholder={t('restaurant.checkout.enterCity')}
              fullWidth
              error={!!errors.city}
              onChange={(e) => onHandleFormChange(e, 'city')}
              helperText={errors.city?.message}
            />
          )}
        />
        <InputLabel variant="subtitle1">
          {t('restaurant.checkout.postalCode')}
        </InputLabel>
        <Controller
          name="postalCode"
          control={control}
          rules={{
            required:
              t('restaurant.checkout.postalCode') +
              ' ' +
              t('restaurant.checkout.isRequired')
          }}
          render={({ field }) => (
            <InputField
              {...field}
              placeholder={t('restaurant.checkout.enterPostalCode')}
              fullWidth
              error={!!errors.postalCode}
              onChange={(e) => onHandleFormChange(e, 'postalCode')}
              helperText={errors.postalCode?.message}
            />
          )}
        />
        <InputLabel variant="subtitle1">
          {t('restaurant.checkout.country')}
        </InputLabel>
        <FormControl fullWidth error={!!errors.country}>
          <Controller
            name="country"
            control={control}
            defaultValue={null}
            rules={{
              required: `${t('restaurant.checkout.country')} ${t(
                'restaurant.checkout.isRequired'
              )}`
            }}
            render={({ field }) => (
              <>
                <StyledDropdown
                  {...field}
                  MenuProps={{
                    style: {
                      zIndex: 1500,
                      marginBottom: '200px',
                      marginTop: '-80px'
                    }
                  }}
                  displayEmpty
                  onChange={(event) => {
                    const selectedCountry = countries.find(
                      (country) => country.name === event.target.value
                    );
                    field.onChange(selectedCountry || null);
                  }}
                  value={field.value?.name || ''}
                >
                  <MenuItem value="" disabled>
                    {t('restaurant.checkout.selectCountry')}
                  </MenuItem>
                  {countries.map((country: Country, index: number) => (
                    <MenuItem key={index} value={country.name}>
                      {country.name}
                    </MenuItem>
                  ))}
                </StyledDropdown>
                <StyledCountryErrorText>
                  {errors.country?.message}
                </StyledCountryErrorText>
              </>
            )}
          />
        </FormControl>
      </form>
      {isEditing ? (
        <ButtonFooter>
          <CancelButton onClick={handleCancelClick}>
            {t('restaurant.main.cancel')}
          </CancelButton>
          <ContinueButton onClick={handlePress}>
            {t('restaurant.checkout.update')}
          </ContinueButton>
        </ButtonFooter>
      ) : (
        <ContinueButton
          variant="contained"
          disableRipple
          onClick={handlePress}
          data-testid="continue-to-card-info"
          disabled={false}
        >
          {t('restaurant.checkout.continueToCardInfo')}
        </ContinueButton>
      )}
    </StyledFormContainer>
  );
};

export default CollectBilling;
