import { KeyboardEvent } from 'react';
import { useParams, generatePath, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import AppRoute from '../../../../routing/AppRoute';
import { StoreInterface } from '../../../../types/stores';
import { useCartV2 } from '../../../../hooks/useCartV2';
import { useCalculate } from '../../../../hooks/useCalculate';
import { styled } from '@mui/material/styles';
import Button, { ButtonProps } from '@mui/material/Button';
import { Typography } from '@mui/material';
import FormatPrice from '../FormatPrice/FormatPrice';

interface CartButtonProps {
  store: StoreInterface;
}

const CartButtonStyled = styled(Button)<ButtonProps>(({ theme }) => ({
  color: theme.colors.partner.button.primary.font
}));
const CartButtonTextStyled = styled(Typography)(({ theme }) => ({
  color: theme.colors.partner.button.primary.font
}));
const CartContentStyled = styled('div')(({ theme }) => ({
  backgroundColor: theme.colors.partner.button.primary.background,
  borderRadius: theme.spacing(2),
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignSelf: 'stretch',
  flex: 1,
  position: 'relative',
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4),
  color: theme.colors.partner.button.primary.font
}));
const CartTextContentStyled = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  height: theme.spacing(12)
}));
const CartTextStyled = styled('div')(({ theme }) => ({
  marginLeft: theme.spacing(2)
}));
const CartPriceStyled = styled('div')(({ theme }) => ({
  marginLeft: theme.spacing(2)
}));

const CartButton = ({ store }: CartButtonProps) => {
  const { t } = useTranslation();
  const { storeId: shortId } = useParams();
  const { getQuantity } = useCartV2();
  const cartQuantity = getQuantity();
  const { cartCalculations } = useCalculate(store);
  const navigate = useNavigate();

  const cartText =
    cartQuantity > 1
      ? `${cartQuantity} ${t('restaurant.cart.item_plural')}`
      : `${cartQuantity} ${t('restaurant.cart.item')}`;

  const cartPrice = FormatPrice(cartCalculations?.data?.summary.total);

  const cartLink = generatePath(AppRoute.CART, {
    storeId: String(shortId)
  });

  const handleClick = () => {
    navigate(cartLink);
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key !== 'Enter') {
      return;
    }

    event.stopPropagation();
    event.preventDefault();

    handleClick();
  };

  return (
    <CartContentStyled onClick={handleClick} onKeyDown={handleKeyDown}>
      <CartTextContentStyled data-testid="cart-content">
        <ShoppingCartOutlinedIcon />
        <CartTextStyled data-testid="cart-text">{cartText}</CartTextStyled>
        {cartPrice && (
          <>
            <CartTextStyled>•</CartTextStyled>
            <CartPriceStyled data-testid="cart-price">
              {cartPrice}
            </CartPriceStyled>
          </>
        )}
      </CartTextContentStyled>
      <CartButtonStyled
        data-testid="cart-button"
        aria-label={`${cartText} ${cartPrice}, ${t('restaurant.cart.view')}`}
      >
        <CartButtonTextStyled variant="body1" data-testid="cart-button-text">
          {t('restaurant.cart.view')}
        </CartButtonTextStyled>
      </CartButtonStyled>
    </CartContentStyled>
  );
};

export default CartButton;
