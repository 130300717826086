import CheckIcon from '@mui/icons-material/Check';
import { useTheme } from '@mui/material/styles';
import { ToastBar, Toaster } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

export const SuccessToaster = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <Toaster
      gutter={20}
      containerClassName='customToasterClass'>
      {(toast) => (
        <ToastBar
          toast={toast}
          style={{
            backgroundColor: theme.colors.success[500],
            color: theme.colors.base.black
          }}
        >
          {({ message }) => (
            <>
              {(toast.message === t('restaurant.menu.addedToOrder') ||
                toast.message === t('restaurant.menu.updatedItemToCart')) && (
                <CheckIcon />
              )}
              <span>{message}</span>
            </>
          )}
        </ToastBar>
      )}
    </Toaster>
  );
};
