import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import Radio from '@mui/material/Radio';
import FormControl from '@mui/material/FormControl';
import { default as LogoIcon } from '../../../../components/AllergenIcon/AllergenIcon';
import { ReactComponent as CreditCardSVG } from '../../../../assets/images/logo-icons/credit-card.svg';
import { ReactComponent as ApplePaySVG } from '../../../../assets/images/logo-icons/apple-pay.svg';
import { ReactComponent as GooglePaySVG } from '../../../../assets/images/logo-icons/google-pay.svg';
import Typography from '@mui/material/Typography';
import CreditCard from '../CreditCard';

interface SelectPaymentProps {
  transactionTotal: number;
}

const Title = styled('h6')(({ theme }) => ({
  fontSize: theme.spacing(5),
  fontWeight: 700,
  marginTop: theme.spacing(25)
}));

const SelectionContainer = styled('div')(({ theme }) => ({
  border: `1px solid ${theme.colors.gray[300]}`,
  borderRadius: '4px',
  marginBottom: theme.spacing(4),
  fontSize: theme.spacing(4),
  padding: theme.spacing(5),
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  cursor: 'pointer'
}));

const SelectionHeader = styled('div')(({ theme }) => ({
  fontSize: theme.spacing(4),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start'
}));

const StyledRadio = styled(Radio)(({ theme }) => ({
  padding: 0,
  marginRight: theme.spacing(4)
}));

const PaymentText = styled(Typography)(({ theme }) => ({
  variant: 'body1',
  marginLeft: theme.spacing(4)
}));

const SelectPaymentMethod = ({ transactionTotal }: SelectPaymentProps) => {
  const { t } = useTranslation();

  const [paymentMethod, setPaymentMethod] = useState<string>('');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPaymentMethod(event.target.value);
  };

  const handleContainerClick = (value: string) => {
    setPaymentMethod(value);
  };

  return (
    <>
      <Title>{t('restaurant.checkout.selectPaymentMethod')}</Title>
      <FormControl component="fieldset" style={{ width: '100%' }}>
        <SelectionContainer onClick={() => handleContainerClick('creditCard')}>
          <SelectionHeader>
            <StyledRadio
              data-testid="cc-radio"
              checked={paymentMethod === 'creditCard'}
              onChange={handleChange}
              value="creditCard"
              name="paymentMethod"
            />
            <LogoIcon
              height="24"
              width="40"
              AllergenSVG={CreditCardSVG}
              title="knife icon"
              dropShadow={false}
              ariaHidden={true}
            />
            <PaymentText>{t('restaurant.checkout.creditCard')}</PaymentText>
          </SelectionHeader>
          {paymentMethod === 'creditCard' && (
            <CreditCard transactionTotal={transactionTotal} />
          )}
        </SelectionContainer>
        <SelectionContainer onClick={() => handleContainerClick('applePay')}>
          <SelectionHeader>
            <StyledRadio
              data-testid="apple-radio"
              checked={paymentMethod === 'applePay'}
              onChange={handleChange}
              value="applePay"
              name="paymentMethod"
            />
            <LogoIcon
              height="24"
              width="40"
              AllergenSVG={ApplePaySVG}
              title="knife icon"
              dropShadow={false}
              ariaHidden={true}
            />
            <PaymentText>{t('restaurant.checkout.applePay')}</PaymentText>
          </SelectionHeader>
        </SelectionContainer>
        <SelectionContainer onClick={() => handleContainerClick('googlePay')}>
          <SelectionHeader>
            <StyledRadio
              data-testid="google-radio"
              checked={paymentMethod === 'googlePay'}
              onChange={handleChange}
              value="googlePay"
              name="paymentMethod"
            />
            <LogoIcon
              height="24"
              width="40"
              AllergenSVG={GooglePaySVG}
              title="knife icon"
              dropShadow={false}
              ariaHidden={true}
            />
            <PaymentText>{t('restaurant.checkout.googlePay')}</PaymentText>
          </SelectionHeader>
        </SelectionContainer>
      </FormControl>
    </>
  );
};

export default SelectPaymentMethod;
