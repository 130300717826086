import { useParams } from 'react-router-dom';
import { useSiteConfig } from '../../hooks/useSiteConfig';
import { useStore } from '../../hooks/useStore';
import { CartProviderV2 } from '../../contexts/Cart/CartContextV2';
import CheckoutContent from './components/CheckoutContent/CheckoutContent';
import useStoreClosedCheck from '../../hooks/useStoreClosedCheck';
import { Helmet } from 'react-helmet';
import { useColorConfig } from '../../hooks/useColorConfig';
import { ThemeProvider } from '@mui/material';
import AppLayout from '../../layouts/AppLayout';

const Checkout = () => {
  const { storeId: shortId } = useParams();
  const { partnerConfig: siteConfig, paymentGateway } = useSiteConfig();
  const { data: store } = useStore(siteConfig.partnerId, shortId ?? '');
  const theme = useColorConfig();

  useStoreClosedCheck(store);

  if (!store || !shortId) {
    return null;
  }

  return (
    <ThemeProvider theme={theme}>
      <AppLayout>
        <CartProviderV2 shortId={shortId}>
          <Helmet>
            <title>Checkout</title>
          </Helmet>
          <CheckoutContent
            store={store}
            siteConfig={siteConfig}
            paymentGateway={paymentGateway}
          />
        </CartProviderV2>
      </AppLayout>
    </ThemeProvider>
  );
};

export default Checkout;
