import React, { useEffect, useRef } from 'react';

interface IframeRendererProps {
  iframeHtml: string;
}

const IframeRenderer: React.FC<IframeRendererProps> = ({ iframeHtml }) => {
  const iframeContainerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (iframeContainerRef.current) {
      const updatedIframeHtml = `
        <style>
          #hpc--card-frame {
            height: 640px !important;
          }
        </style>
        ${iframeHtml}
      `;
      iframeContainerRef.current.innerHTML = updatedIframeHtml;
    }
  }, [iframeHtml]);

  return <div data-testid="iframe-renderer" ref={iframeContainerRef} />;
};

export default IframeRenderer;
