/* eslint-disable import/no-extraneous-dependencies */
import { useTranslation } from 'react-i18next';
import {
  Controller,
  useFormContext,
  FieldError,
  FieldErrorsImpl,
  Merge
} from 'react-hook-form';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { ChangeEvent, useState } from 'react';
import { MuiTelInput, MuiTelInputCountry } from 'mui-tel-input';
import { useCountrySelectorObserver } from '../../../../hooks/useCountrySelectorObserver';
import { usePhoneCountries } from '../../../../hooks/usePhoneCountries';

interface GuestInfoProps {
  tableManagement: string;
}

const GuestInfoSectionDivider = styled('div')(({ theme }) => ({
  borderTop: '1px solid',
  borderColor: theme.colors.gray[300]
}));

const GuestInfoContainer = styled(Box)(({ theme }) => ({
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4),
  marginBottom: theme.spacing(6)
}));

const GuestInfoTitle = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2)
}));

const InputField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(2.75)
}));

const StyledTelephoneInput = styled(MuiTelInput)(({ theme }) => ({
  '.MuiTypography-root': {
    marginLeft: theme.spacing(2)
  },
  '.MuiFormHelperText-root': {
    color: theme.colors.gray[500]
  }
}));

const StyledPhoneInputContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  button: {
    borderRadius: 0,
    height: theme.spacing(9),
    width: theme.spacing(15),
    paddingTop: 0,
    paddingBottom: 0,
    picture: {
      '&::after': {
        paddingLeft: theme.spacing(1),
        content: 'url("/expand_more_black_24dp.svg")',
        paddingTop: theme.spacing(1)
      }
    }
  },
  input: {
    paddingLeft: theme.spacing(4)
  }
}));

const GuestInformation = ({ tableManagement }: GuestInfoProps) => {
  const { t } = useTranslation();
  const phoneCountries = usePhoneCountries();

  const {
    control,
    formState: { errors },
    setValue,
    register,
    clearErrors
  } = useFormContext();

  useCountrySelectorObserver();

  const handleOnBlur = () => {};

  const handleOnKeyDown = (field: string) => {
    clearErrors(field);
  };

  const generatePhoneErrorMessage = (
    errorType:
      | string
      | FieldError
      | Merge<FieldError, FieldErrorsImpl>
      | (string & { _?: undefined })
      | undefined
  ) => {
    switch (errorType) {
      case 'required': {
        return t('restaurant.checkout.emptyPhoneError');
      }
      case 'validated': {
        return t('restaurant.checkout.invalidPhoneError');
      }
      default: {
        return '';
      }
    }
  };

  const generateNameErrorMessage = (
    errorType:
      | string
      | FieldError
      | Merge<FieldError, FieldErrorsImpl>
      | (string & { _?: undefined })
      | undefined
  ) => {
    switch (errorType) {
      case 'required': {
        return t('restaurant.checkout.emptyNameError');
      }
      default: {
        return t('restaurant.checkout.invalidNameError');
      }
    }
  };

  const showTableInput = tableManagement === 'table';
  const [tableCount, setTableCount] = useState('0/10');

  const generateDeliveryLocationErrorMessage = (
    errorType:
      | string
      | FieldError
      | Merge<FieldError, FieldErrorsImpl>
      | (string & { _?: undefined })
      | undefined
  ) => {
    switch (errorType) {
      case 'required': {
        return t('restaurant.checkout.emptyLoc');
      }
      default: {
        return tableCount;
      }
    }
  };

  const [phone, setPhone] = useState('');

  const handleChange = (newPhone: string) => {
    setPhone(newPhone);
    setValue('phone', newPhone);
  };

  let localeLanguage =
    window.navigator.language.split('-').length > 1
      ? (window.navigator.language
          .split('-')[1]
          .toUpperCase() as MuiTelInputCountry)
      : (window.navigator.language.toUpperCase() as MuiTelInputCountry);

  if (
    phoneCountries.data &&
    phoneCountries.data.indexOf(localeLanguage) === -1
  ) {
    localeLanguage = 'US';
  }

  return (
    <>
      <GuestInfoContainer data-testid="guest-payment-info-form">
        <GuestInfoTitle variant="h5" data-testid="guest-info-title">
          {t('restaurant.checkout.guestInformation')}
        </GuestInfoTitle>
        {showTableInput && (
          <>
            <Controller
              name="deliveryLocation"
              control={control}
              render={({ field }) => (
                <InputField
                  {...field}
                  {...register('deliveryLocation')}
                  type="text"
                  id="delivery-location-input"
                  data-testid="delivery-location-input"
                  onChange={(e) => {
                    setTableCount(e.target.value.length + '/10');
                  }}
                  onKeyDown={() => handleOnKeyDown('deliveryLocation')}
                  label={t('restaurant.checkout.deliveryLocation')}
                  fullWidth
                  required
                  placeholder={t(
                    'restaurant.checkout.deliveryLocationPlaceholder'
                  )}
                  InputLabelProps={{ required: false }}
                  inputProps={{
                    minLength: {
                      value: 1,
                      message: 'Required'
                    },
                    maxLength: 10
                  }}
                  error={!!errors.deliveryLocation}
                  aria-description={
                    errors.deliveryLocation
                      ? generateDeliveryLocationErrorMessage(
                          errors.deliveryLocation.type
                        )
                      : tableCount
                  }
                  helperText={
                    errors.deliveryLocation
                      ? generateDeliveryLocationErrorMessage(
                          errors.deliveryLocation.type
                        )
                      : tableCount
                  }
                  FormHelperTextProps={{ style: { textAlign: 'right' } }}
                  sx={{
                    marginBottom: errors.deliveryLocation ? '20px' : 'none'
                  }}
                />
              )}
            />
          </>
        )}
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <InputField
              {...field}
              {...register('name')}
              autoComplete="name"
              type="text"
              id="name-input"
              data-testid="name-input"
              label={t('restaurant.checkout.name')}
              fullWidth
              required
              placeholder={t('restaurant.checkout.namePlaceholder')}
              InputLabelProps={{ required: false }}
              error={!!errors.name}
              aria-description={
                errors.name ? generateNameErrorMessage(errors.name.type) : ' '
              }
              helperText={
                errors.name ? generateNameErrorMessage(errors.name.type) : ' '
              }
              sx={{ marginBottom: errors.name ? '20px' : 'none' }}
            />
          )}
        />
        <StyledPhoneInputContainer>
          {phoneCountries.data && phoneCountries.data.length > 0 ? (
            <Controller
              name="phone"
              control={control}
              render={({ field }) => (
                <StyledTelephoneInput
                  {...field}
                  helperText={
                    errors.phone
                      ? generatePhoneErrorMessage(errors.phone.type)
                      : t('restaurant.checkout.phoneSmsInfo')
                  }
                  aria-description={
                    errors.phone
                      ? generatePhoneErrorMessage(errors.phone.type)
                      : t('restaurant.checkout.phoneSmsInfo')
                  }
                  ref={register('phone').ref}
                  error={!!errors.phone}
                  value={phone}
                  onChange={handleChange}
                  onBlur={handleOnBlur}
                  onKeyDown={() => handleOnKeyDown('phone')}
                  forceCallingCode
                  label="Phone Number"
                  fullWidth
                  required
                  focusOnSelectCountry
                  onlyCountries={
                    phoneCountries.data && phoneCountries.data.length
                      ? phoneCountries.data
                      : [localeLanguage]
                  }
                  defaultCountry={localeLanguage}
                  preferredCountries={[localeLanguage]}
                  id="phone-number"
                  data-testid="phone-number"
                  name="phone-number"
                  placeholder="Enter Phone Number"
                />
              )}
            />
          ) : null}
        </StyledPhoneInputContainer>
      </GuestInfoContainer>
      <GuestInfoSectionDivider />
    </>
  );
};

export default GuestInformation;
