import { TaxLabel } from '../../../services/orderService';
import { ModItem } from '../../Menu/types';

export interface CartItem {
  productId: number;
  title: string;
  description: string;
  category: string;
  retailPrice: number;
  modifiers: { [index: string]: ModItem };
  quantity: number;
  attributes: CartItemAttribute[];
  discountTotal: number;
  itemTotalDiscountPrice: number;
  itemTotalPrice: number;
  tax: number;
  gross: number;
  unitRRPGross: number;
  itemNotes: string;
}

export interface CartItemModifier {
  productId: number;
  name: string;
  quantity: number;
  price: number;
}

export interface CartItemAttribute {
  name: string;
}

export interface TipPreview {
  percent: number;
  amount: number;
}

export interface OrderSummary {
  discount: number;
  fee: number;
  previewRoundUp: number;
  subtotal: number;
  tax: number;
  taxLabel: TaxLabel;
  tip: number;
  tipPercentage?: number;
  tipPreview: TipPreview[];
  total: number;
}

export interface CartCalculation {
  summary: OrderSummary;
  cart: CartCalculationCartItem[];
}

export interface CartCalculationCartItem {
  clientItemId: string;
  itemTotalDiscountPrice: number;
  itemTotalPrice: number;
  productId: number;
  quantity: number;
  modifiers: CartCalculationCartItemModifier[];
}

export interface CartCalculationCartItemModifier {
  discountPrice: number;
  price: number;
  productId: number;
  quantity: number;
}

export interface Tip {
  type: string | null;
  value: number | null;
}

export enum CartStatus {
  ACTIVE = 'ACTIVE',
  EXPIRING = 'EXPIRING',
  EXPIRED = 'EXPIRED'
}
