import { backendConfig } from '../../../config/appConfig';
import axios from 'axios';
import { getTestOverrideSite } from './getTestOverrideSite';

const updatePaymentIntent = async (
  clientSecret: string,
  amount: number,
  siteId: string
) => {
  const updatePaymentUrl = `${backendConfig.apiBaseUrl}/eat/payment/partner/${siteId}`;
  const paymentIntentId = clientSecret ? clientSecret.split('_secret')[0] : '';
  const requestBody = JSON.stringify({
    amount: amount,
    paymentIntentId: paymentIntentId
  });

  const axiosConfig = {
    method: 'PUT',
    url: updatePaymentUrl,
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': backendConfig.apiKey,
      'x-test': getTestOverrideSite()
    },
    data: requestBody
  };
  try {
    await axios(axiosConfig);
    return true;
  } catch (error) {
    throw error;
  }
};

export default updatePaymentIntent;
