import { STORES } from '../config/backendServicesEndpoints';
import { StoreDay, StoreInterface } from '../types/stores';
import { http } from './http';

// @ts-expect-error Days is an array here
function sortDays(days) {
  const storeDays = {};
  const daysOfTheWeek = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday'
  ];

  daysOfTheWeek.map((dayOfTheWeek) => {
    storeDays[dayOfTheWeek] = days.filter(
      (day: StoreDay) => typeof day[dayOfTheWeek] !== 'undefined'
    )[0][dayOfTheWeek];
  });
  return storeDays;
}

export function fetchStores(siteId: string): Promise<StoreInterface[]> {
  return http.get(`${STORES}/${siteId}/store`).then((response) => {
    const stores: StoreInterface[] = response.data.map(
      (store: StoreInterface) => {
        const days = sortDays(store?.days);

        return {
          ...store,
          days: days
        };
      }
    );

    return stores;
  });
}

export function fetchStore(
  siteId: string,
  shortId: string | undefined
): Promise<StoreInterface | null> {
  return http.get(`${STORES}/${siteId}/store`).then((response) => {
    const store = response.data.find(
      (item: StoreInterface) =>
        item.shortId.toLowerCase() === shortId?.toLowerCase()
    );
    if (!store) {
      return null;
    }

    store.days = sortDays(store?.days);
    return store;
  });
}
