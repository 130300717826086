import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';

import allergentEmptyCircle from '../../assets/images/allergen-icons/allergen-empty-circle.svg';

const StyledRemaningAllergensIcon = styled('div')(({ theme }) => ({
  width: 24,
  height: 24,
  background: `url(${allergentEmptyCircle}) center`,
  display: 'inline-flex',
  position: 'relative',
  justifyContent: 'center',
  alignItems: 'center',
  fontWeight: '600',
  paddingRight: 0.5
}));

const StyledSingleDigitText = styled('span')(({ theme }) => ({
  fontSize: theme.spacing(3)
}));

const StyledDoubleDigitText = styled('span')(({ theme }) => ({
  fontSize: theme.spacing(2.5)
}));

const StyledContainer = styled(Box)(({ }) => ({
  marginTop: 'auto',
  display: 'flex'
}));

interface AllergensDisplayProps {
  /**
   * How many allergen icons to show before showing the number indicator for the rest of the icons
   * not passing this prop or passing 0 will render all icons
   * */
  limit?: number;
  children: React.ReactNode;
}

export default function AllergensDisplay({
  limit,
  children
}: AllergensDisplayProps) {
  const total = React.Children.count(children);

  if (total === 0) {
    return null;
  }

  if (!limit || limit === 0) {
    return <StyledContainer>{children}</StyledContainer>;
  }

  let slice = React.Children.toArray(children).slice(0, limit);

  //append the remaining items counter icon
  if (limit && limit < total) {
    const totalRemaining = total - limit;

    slice = [
      ...slice,
      <StyledRemaningAllergensIcon data-testid="allergen-icon-remaining">
        {totalRemaining > 9 ? (
          <StyledDoubleDigitText>+{totalRemaining}</StyledDoubleDigitText>
        ) : (
          <StyledSingleDigitText>+{totalRemaining}</StyledSingleDigitText>
        )}
      </StyledRemaningAllergensIcon>
    ];
  }

  const result = React.Children.map(slice, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child);
    }
    return null;
  });

  return <StyledContainer>{result}</StyledContainer>;
}
