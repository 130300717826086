export type GatewayMessage =
  | 'declined'
  | 'incomplete'
  | 'unknown'
  | 'no_capacity'
  | 'invalid_adv_order'
  | 'ignite_process_error'
  | 'stripe_confirm_payment_error'
  | 'bad_cart_item_suspended';

export class GatewayError extends Error {
  message = '';

  title: string | null | undefined = undefined;

  description: string | null | undefined = undefined;

  details: object = {};

  errorCode: string | undefined | null;

  constructor(
    message: GatewayMessage,
    title?: string | null,
    description?: string | null,
    details?: object,
    errorCode?: string
  ) {
    super();
    this.message = message;
    this.title = title;
    this.description = description;
    this.details = details ?? {};
    this.errorCode = errorCode;
  }
}
