import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import { FC } from 'react';
import { MenuItem } from '../../Menu/types';

interface MenuItemImageProps {
  item: MenuItem;
  screenWidth: number;
}

const ImageContainer = styled(Box, {
  shouldForwardProp: (prop: string) =>
    prop !== 'menuItemImageUrl' && prop !== 'isSuspended'
})<{ menuItemImageUrl: string; alt: string; isSuspended: boolean }>(
  ({ theme, menuItemImageUrl, isSuspended }) => ({
    height: menuItemImageUrl !== null && menuItemImageUrl !== '' ? 210 : 0,
    backgroundImage: `url(${menuItemImageUrl})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    // paddingRight: theme.spacing(3.84),
    paddingBottom: theme.spacing(3),
    marginTop: theme.spacing(13),
    marginBottom: theme.spacing(4),
    color: theme.colors.base.white
  })
);

const MenuItemImage: FC<MenuItemImageProps> = (props) => {
  return (
    <ImageContainer
      menuItemImageUrl={props.item.imageUrl}
      isSuspended={props.item.isSuspended}
      alt="A picture of the menu item"
    />
  );
};

export default MenuItemImage;
