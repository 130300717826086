import { AxiosResponse } from 'axios';
import {
  CALCULATE,
  ORDER_STATUS,
  PROCESS
} from '../config/backendServicesEndpoints';
import {
  CalculateOrderObject,
  PostOrder,
  ProcessOrderResponse
} from '../types/fetch';
import { http } from './http';
import { getTestOverrideSite } from '../utils/Payments/Stripe/getTestOverrideSite';
import { CartCalculation } from '../features/Cart/types';
import { OrderStatus, OrderType } from '../types/order';

export interface FetchOrderStatusResponse {
  id: string;
  restaurantId: string;
  orderNumber: string;
  orderDate: string;
  location?: string;
  orderType: OrderType;
  status: OrderStatus;
  receipt?: Payment;
  cart: CartItem[];
  tip: number;
  currency: string;
  roundUp: number;
  summary: Summary;
}

export interface Payment {
  lastFour: number;
  dBA: string;
  cardType: string;
}

export interface CartItem {
  productId: number;
  productName: string;
  itemTotalPrice: number;
  menuPrice: number;
  quantity: number;
  modifiers: Modifier[];
}

export interface Modifier {
  productId: number;
  productName: string;
  quantity: number;
  menuPrice: number;
}

export type TaxLabel = 'tax' | 'VAT';

export interface Summary {
  tax: number;
  taxLabel: TaxLabel;
  subtotal: number;
  discount: number;
  // discountPercentage: number;
  fee: number;
  total: number;
}

export const fetchOrderStatus = async (
  publicOrderId: string,
  storeUuid?: string
): Promise<AxiosResponse<FetchOrderStatusResponse>> => {
  return http.get(`${ORDER_STATUS}/${storeUuid}/order/${publicOrderId}`);
};

export const postOrderProcessing = async (
  order: PostOrder,
  storeId?: string
): Promise<AxiosResponse<ProcessOrderResponse>> => {
  return http.post(`${PROCESS}/restaurant/${storeId}`, order, {
    headers: { 'x-test': getTestOverrideSite() }
  });
};

export const fetchOrderCalculations = async (
  storeId: string,
  calculateOrderObject: CalculateOrderObject
): Promise<AxiosResponse<CartCalculation>> => {
  return http.post(`${CALCULATE}/restaurant/${storeId}`, calculateOrderObject);
};
