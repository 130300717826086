import { ChangeEvent, FC } from 'react';
import { styled } from '@mui/material/styles';
import DropdownItem from '../DropdownItem/DropdownItem';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import {
  MenuItemModGroup,
  MenuModifierItem,
  ModItem
} from '../../../Menu/types';

interface CheckBoxGroupProps {
  modName: string;
  group: MenuItemModGroup;
  selectedGroupItems: { [index: string]: ModItem };
  addSelection: (itemModifier: ModItem) => void;
  removeSelection: (itemModifier: ModItem) => void;
}

const StyledCheckBox = styled(Checkbox)(({ theme }) => ({
  color: theme.colors.gray.checkbox,
  '& .MuiSvgIcon-root': {
    position: 'relative',
    zIndex: 1
  },
  '&.Mui-checked': {
    color: theme.colors.partner.button.primary.background
  },
  '&.Mui-checked::before': {
    content: '""',
    backgroundColor: theme.colors.partner.button.primary.font,
    position: 'absolute',
    zIndex: 0,
    top: '14px',
    left: '14px',
    width: '1.15rem',
    height: '1.1rem'
  }
}));

const CheckBoxGroup: FC<CheckBoxGroupProps> = (props) => {
  const { modName, addSelection, removeSelection, group, selectedGroupItems } =
    props;

  const handleOnChange = (
    event: ChangeEvent<HTMLInputElement>,
    item: MenuModifierItem,
    inverted: boolean
  ) => {
    let checked = event.target.checked;

    if (inverted) {
      checked = !checked;
    }

    const selectedModifier: ModItem = {
      ...item,
      quantity: 1,
      modifierName: modName
    };

    if (!checked) {
      removeSelection(selectedModifier);
      return;
    }

    addSelection(selectedModifier);
  };

  return (
    <FormGroup>
      {group.modItems.map((item, idx) => {
        let checked = false;
        let inverted = false;

        if (group.style.toLowerCase().includes('inverse')) {
          checked = true;
          inverted = true;
        }
        if (selectedGroupItems[item.name]) {
          checked = !inverted;
        }
        return (
          <DropdownItem
            value={item}
            key={`${item.name}_${idx}`}
            isCheckBox={true}
            className="checkBox"
          >
            <StyledCheckBox
              checked={checked}
              onChange={(event) => handleOnChange(event, item, inverted)}
              data-testid={`${item.name.replace(/[^A-Z0-9]+/gi, '_')}-checkbox`}
            />
          </DropdownItem>
        );
      })}
    </FormGroup>
  );
};

export default CheckBoxGroup;
