import OrderHeader from '../OrderHeader/OrderHeader';
import { useTranslation } from 'react-i18next';
import OrderInfo from '../OrderInfo/OrderInfo';
import { OrderStatus } from '../../../../types/order';
import { FetchOrderStatusResponse } from '../../../../services/orderService';
import OrderPayment from '../OrderPayment/OrderPayment';
import OrderTotal from '../../../../components/OrderTotal/OrderTotal';
import { StoreInterface } from '../../../../types/stores';
import OrderSummary from '../OrderSummary/OrderSummary';
import { styled } from '@mui/material';

interface OrderModalProps {
  order: FetchOrderStatusResponse;
  store: StoreInterface;
}

const OrderModalContainer = styled('div')(({ theme }) => ({
  paddingBottom: theme.spacing(6)
}));

const OrderModal = (props: OrderModalProps) => {
  const { order } = props;
  const { t } = useTranslation();

  return (
    <OrderModalContainer>
      <OrderHeader
        text={
          order.location
            ? t('restaurant.order.beingPrepared')
            : order.status === OrderStatus.COMPLETED
            ? t('restaurant.order.readyForPickup')
            : t('restaurant.order.confirmed')
        }
        storedId={props.store.shortId}
      />
      <OrderInfo
        isOrderComplete={order.status === OrderStatus.COMPLETED}
        orderNumber={order.orderNumber}
        pickupTime={new Date(order.orderDate)}
        store={props.store}
        location={order.location}
      />
      {order.receipt?.lastFour && (
        <OrderPayment payment={order.receipt} total={order.summary.total} />
      )}
      <OrderSummary items={order.cart} />
      <OrderTotal
        showTip={!!order.tip}
        showRoundUp={!!order.roundUp}
        cartCalculations={{
          ...order.summary,
          previewRoundUp: order.roundUp,
          tip: order.tip,
          tipPreview: []
        }}
      />
    </OrderModalContainer>
  );
};

export default OrderModal;
