import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { format, parse } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { StoreInterface, StoreShiftInterface } from '../../types/stores';
import { ReactComponent as WarningIcon } from '../../assets/images/logo-icons/warning-circle.svg';


const StyledHoursContainer = styled('div')(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  marginLeft: theme.spacing(8),
  marginRight: theme.spacing(8)
}));

const StyledHoursContainerTitle = styled(Typography)(({ theme }) => ({
  fontSize: theme.spacing(4),
  fontWeight: 400,
  textAlign: 'center'
}));

const StyledHoursContainerCaption = styled(Typography)(({ theme }) => ({
  fontSize: theme.spacing(3),
  fontWeight: 400,
  marginTop: theme.spacing(1),
  textAlign: 'center'
}));

const StyledHoursList = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(3)
}));

const StyledDay = styled(Box)(({ theme }) => ({
  width: theme.spacing(82),
  borderRadius: theme.spacing(2),
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  padding: theme.spacing(1),
  paddingLeft: theme.spacing(5),
  paddingRight: theme.spacing(5),
  alignItems: 'center',
  '&:nth-of-type(odd)': {
    backgroundColor: theme.colors.gray[200]
  }
}));

const StyledDayText = styled(Typography)(({ theme }) => ({
  fontSize: theme.spacing(3.5),
  fontWeight: 500
}));

const StyledTimeText = styled(Typography)(({ theme }) => ({
  fontSize: theme.spacing(3.5),
  fontWeight: 500,
  textAlign: 'right',
  whiteSpace: 'pre-line'
}));

const StyledShiftTime = styled('span')(() => ({
  display: 'block'
}));

const StyledIsMobileActiveContainer =  styled('div')(({ theme }) => ({
  borderRadius:  theme.spacing(2),
  border: '1px solid',
  borderColor: theme.colors.warning[500],
  background: theme.colors.warning[50],
  display: 'flex',
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(3.5),
  width: '300px'
}));

const StyledIsMobileActiveBanner =  styled('div')(({ theme }) => ({
  padding: theme.spacing(3),
  color: theme.colors.warning[700],
  display: 'inline-flex',
  alignItems: 'center',
  fontSize: theme.spacing(3.5)
}));

const StyledWarningIconContainer = styled('div')(({ theme }) => ({
  paddingRight: theme.spacing(2),
  display: 'flex'
}));

function displayOrderHours(dayKey: string, shifts: StoreShiftInterface[]) {
  return shifts.map((shift) => {
    const startTimeHours = parse(shift.startTime, 'HH:mm', new Date());
    const endTimeHours = parse(shift.endTime, 'HH:mm', new Date());

    const startTime = format(startTimeHours, 'hh:mm a');
    const endTime = format(endTimeHours, 'hh:mm a');

    return (
      <StyledShiftTime key={`${dayKey}-${startTime}-${endTime}`}>
        {startTime} - {endTime}
      </StyledShiftTime>
    );
  });
}

interface HoursProps {
  store: StoreInterface;
}
export default function StoreHours({ store }: HoursProps) {
  const { t } = useTranslation();

  return (
    <StyledHoursContainer data-testid="restaurant-hours-container">
      <StyledHoursContainerTitle>
        {t('restaurant.main.hoursTitle')}
      </StyledHoursContainerTitle>
      {!store.isMobileActive && (
        <StyledIsMobileActiveContainer>
          <StyledIsMobileActiveBanner>
            <StyledWarningIconContainer>
              <WarningIcon />
            </StyledWarningIconContainer>
            {t('restaurant.main.orderingUnavailableTitle')}.
          </StyledIsMobileActiveBanner>
        </StyledIsMobileActiveContainer>
      )}
      <StyledHoursContainerCaption>
        {t('restaurant.main.hoursCaption')}
      </StyledHoursContainerCaption>
      <StyledHoursList>
        {Object.keys(store.days).map((dayKey) => (
          <StyledDay key={`ordering-hours-${dayKey}`}>
            <StyledDayText>{dayKey}</StyledDayText>
            <StyledTimeText>
              {store.days[dayKey].length === 0
                ? t('restaurant.main.mobileClosed')
                : displayOrderHours(dayKey, store.days[dayKey])}
            </StyledTimeText>
          </StyledDay>
        ))}
      </StyledHoursList>
    </StyledHoursContainer>
  );
}
