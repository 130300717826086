import { useMemo } from 'react';
import { styled } from '@mui/material';
import MenuNav from '../MenuNav/MenuNav';
import LoadingMenu from '../LoadingMenu/LoadingMenu';
import ErrorMenu from '../ErrorMenu/ErrorMenu';
import { useMenu } from '../../../../hooks/useMenu';
import MenuCategory from '../MenuCategory/MenuCategory';
import { StoreInterface } from '../../../../types/stores';
import { useCategoryScroll } from '../../../../hooks/useCategoryScroll';
import { MenuItem } from '../../types';

interface MenuDetailProps {
  store: StoreInterface;
}

const MenuDetailContainer = styled('div')(({ theme }) => ({
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4),
  paddingBottom: theme.spacing(16)
}));

const MenuLoadingContainer = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(4),
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4),
  display: 'flex',
  justifyContent: 'center'
}));

const MenuDetail = ({ store }: MenuDetailProps) => {
  const { data, isFetching, isError, refetch, isRefetching, isRefetchError } =
    useMenu(store.id);

  const availableCategories: { [key: string]: MenuItem[] } = useMemo(
    () =>
      data
        ? data.menuItems.reduce((acc, cur) => {
            if (!acc[cur.category]) {
              acc[cur.category] = [cur];
            } else {
              acc[cur.category].push(cur);
            }
            return acc;
          }, {})
        : {},
    [data]
  );

  const { tabIndex, setAutoScroll, setManualScroll } =
    useCategoryScroll(availableCategories);

  if (isError || isRefetchError) {
    return (
      <MenuLoadingContainer>
        <ErrorMenu onClick={refetch} />
      </MenuLoadingContainer>
    );
  }

  if (isFetching || isRefetching) {
    return (
      <MenuLoadingContainer>
        <LoadingMenu />
      </MenuLoadingContainer>
    );
  }

  return (
    <MenuDetailContainer data-testid="menu-detail">
      <MenuNav
        tabIndex={tabIndex}
        setTab={(tracker, scrollWait) => setAutoScroll({ tracker, scrollWait })}
      />

      {Object.entries(availableCategories).map((category, index) => (
        <MenuCategory
          key={index}
          content={category[1]}
          category={category[0]}
          screenTracker={(tracker) => setManualScroll({ tracker })}
          store={store}
        />
      ))}
    </MenuDetailContainer>
  );
};

export default MenuDetail;
