import { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { useForm, SubmitHandler } from 'react-hook-form';
import BillingInformation, {
  BillingFormValues
} from '../CollectBilling/CollectBilling';
import IframeRenderer from '../IframeRenderer';
import initialize from '../initialize';
import { useSiteConfig } from '../../../../hooks/useSiteConfig';
import BillingInfoDisplay from '../BillingInfoDisplay/BillingInfoDisplay';
import fetchCountries, { Country } from '../fetchCountries';
interface CreditCardProps {
  transactionTotal: number;
}

const IframeContainer = styled('div', {
  shouldForwardProp: (prop: string) => prop !== 'isVisible'
})<{ isVisible: boolean }>(({ isVisible }) => ({
  width: '100%',
  marginTop: '16px',
  display: isVisible ? 'block' : 'none'
}));

const CreditCard = ({ transactionTotal }: CreditCardProps) => {
  const { partnerConfig } = useSiteConfig();
  const [billingInfo, setBillingInfo] = useState<BillingFormValues | null>(
    null
  );
  const [countries, setCountries] = useState<Country[]>([]);
  const [iframe, setIframe] = useState<string | null>();
  const [isEditing, setIsEditing] = useState<boolean>(true);
  const [isIframeVisible, setIsIframeVisible] = useState<boolean>(true);
  const { setError, clearErrors } = useForm<BillingFormValues>();

  const handleEditClick = () => {
    setIsEditing(true);
    setIsIframeVisible(false);
  };

  const handleCancelClick = () => {
    setIsEditing(false);
    setIsIframeVisible(true);
  };

  const billingOnSubmit: SubmitHandler<BillingFormValues> = async (
    formValues
  ) => {
    if (!formValues.country) {
      setError('country', {
        type: 'manual',
        message: 'Country is required'
      });
      return;
    } else {
      clearErrors('country');
    }

    setBillingInfo(formValues);
    setIsEditing(false);
    setIsIframeVisible(true);

    const response = await initialize(partnerConfig.partnerId, {
      OrderDetails: {
        TransactionTotal: transactionTotal,
        OrderNumber: 123456, // placeholder, make dynamic
        OrderDescription: 'Dine-In' // placeholder, make dynamic
      },
      BillingAddress1: formValues.address,
      BillingCity: formValues.city,
      BillingCountryCode: formValues.country.num3,
      BillingFirstName: formValues.firstName,
      BillingLastName: formValues.lastName,
      BillingPostalCode: formValues.postalCode,
      Email: formValues.email,
      MobilePhone: formValues.mobilePhone,
      Country: formValues.country.alpha2
    });

    if (response?.iFrame) {
      setIframe(response.iFrame);
    }
  };

  useEffect(() => {
    const loadCountries = async () => {
      try {
        const data = await fetchCountries();
        setCountries(data);
      } catch (err) {
        console.error('Failed to fetch countries', err);
      }
    };
    loadCountries();
  }, []);

  useEffect(() => {
    if (iframe) {
      const iframeElement = document.getElementById('hpc--card-frame');
      if (iframeElement) {
        iframeElement.style.height = '640px !important';
      }
    }
  }, [iframe]);

  return (
    <>
      {isEditing ? (
        <BillingInformation
          handleCancelClick={handleCancelClick}
          billingOnSubmit={billingOnSubmit}
          initialValues={billingInfo}
          countries={countries}
          isEditing={isEditing}
        />
      ) : (
        <>
          {billingInfo !== null && (
            <BillingInfoDisplay
              billingInfo={billingInfo}
              handleEditClick={handleEditClick}
            />
          )}
          {iframe && (
            <IframeContainer isVisible={isIframeVisible}>
              <IframeRenderer iframeHtml={iframe} />
            </IframeContainer>
          )}
        </>
      )}
    </>
  );
};

export default CreditCard;
