import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import Typography, { TypographyProps } from '@mui/material/Typography';
import Selection from '../../../../components/Selection/Selection';
import { useTimeDisplay } from '../../../../hooks/useTimeDisplay';
import { StoreInterface } from '../../../../types/stores';

interface CartHeaderProps {
  store: StoreInterface;
}

const BulletStyled = styled('span')(({ theme }) => ({
  position: 'relative',
  top: theme.spacing(0.1),
  paddingRight: '2px'
}));

const LabelStyled = styled('span')(({ theme }) => ({
  paddingRight: theme.spacing(2)
}));

const CartHeaderContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  marginLeft: theme.spacing(4),
  marginTop: theme.spacing(13),
  marginBottom: theme.spacing(3)
}));

const CartHeaderTitle = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontSize: theme.spacing(5),
  fontWeight: 500,
  marginBottom: theme.spacing(8),
  marginTop: theme.spacing(8),
  alignSelf: 'center'
}));

const CartHeaderRestaurant = styled(Typography)<TypographyProps>(
  ({ theme }) => ({
    fontSize: theme.spacing(5),
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0)
  })
);

const PickupTimeSelection = styled('div')<TypographyProps>(({ theme }) => ({
  textAlign: 'center',
  marginTop: theme.spacing(2)
}));

const CartHeader = ({ store }: CartHeaderProps) => {
  const { t } = useTranslation();
  const { pickupTimeDisplay, handleTimeSelection, displayTimes } =
    useTimeDisplay(store.id);

  return (
    <>
      <CartHeaderContainer data-testid="cart-header">
        <CartHeaderTitle variant="h2" data-testid="cart-header-title">
          {t('restaurant.cart.yourCart')}
        </CartHeaderTitle>
        <CartHeaderRestaurant variant="h3" data-testid="cart-header-restaurant">
          {store.name}
        </CartHeaderRestaurant>
        {store.tableManagement !== 'table' && (
          <PickupTimeSelection>
            <LabelStyled>{t('restaurant.main.pickup')}</LabelStyled>
            {store.hasAdvancedOrdering && (
              <>
                <BulletStyled>•</BulletStyled>
                <Selection
                  label={''}
                  placeholder={t('restaurant.main.selectPickupTime')}
                  value={pickupTimeDisplay}
                  options={displayTimes ?? []}
                  onChange={handleTimeSelection}
                  sx={{
                    top: '-1px',
                    color: '#1A1A1A',
                    minWidth: 0,
                    padding: '0',
                    margin: '1px 0',
                    fontWeight: 'bold',
                    fontSize: '16px',
                    fontFamily: 'Roboto',
                    lineHeight: '1.5'
                  }}
                />
              </>
            )}
          </PickupTimeSelection>
        )}
      </CartHeaderContainer>
    </>
  );
};

export default CartHeader;
