import { useParams, useLocation, useSearchParams } from 'react-router-dom';
import MenuDetail from './components/MenuDetail/MenuDetail';
import MenuHeader from './components/MenuHeader';
import CartTrackingDrawer from './components/CartTrackingDrawer';
import ViewCartBanner from './components/ViewCartBanner/ViewCartBanner';
import { CartProviderV2 } from '../../contexts/Cart/CartContextV2';
import { useSiteConfig } from '../../hooks/useSiteConfig';
import { useStore } from '../../hooks/useStore';
import PickupTimeAlert from '../../components/PickupTimeAlert/PickupTimeAlert';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { SessionKeys } from '../../utils/Session/Session';
import { ThemeProvider } from '@mui/material';
import AppLayout from '../../layouts/AppLayout';
import { useColorConfig } from '../../hooks/useColorConfig';
import OrderingUnavailableDialog from '../../components/OrderingUnavailableDialog/OrderingUnavailableDialog';

const Menu = () => {
  const siteConfig = useSiteConfig();
  const { storeId: shortId } = useParams();
  const { data: store } = useStore(
    siteConfig.partnerConfig.partnerId,
    shortId ?? ''
  );
  const { state } = useLocation();
  const [searchParams] = useSearchParams();
  const theme = useColorConfig();
  const [openOrderingUnavailableDialog, setOpenOrderingUnavailableDialog] =
    useState<boolean>(store?.isMobileActive === false ?? false);

  const scrollPosition = sessionStorage.getItem(SessionKeys.SCROLL_POSITION);

  useEffect(() => {
    if (!scrollPosition) {
      return;
    }

    window.scrollTo(0, Number(scrollPosition));
    sessionStorage.removeItem(SessionKeys.SCROLL_POSITION);
  }, [scrollPosition]);

  if (searchParams.get('ordering') === 'dinein') {
    sessionStorage.setItem(SessionKeys.DINING_IN, 'true');
  }

  if (!siteConfig || !store || !shortId) {
    return null;
  }

  return (
    <ThemeProvider theme={theme}>
      <AppLayout>
        <CartProviderV2 shortId={shortId}>
          <Helmet>
            <title>
              {state?.title ? `${state?.title} Menu Page` : 'Menu Page'}
            </title>
          </Helmet>
          <ViewCartBanner store={store} />
          <OrderingUnavailableDialog
            open={openOrderingUnavailableDialog}
            close={() =>
              setOpenOrderingUnavailableDialog(!openOrderingUnavailableDialog)
            }
          />
          <CartTrackingDrawer store={store} />
          <MenuHeader store={store} />
          <MenuDetail store={store} />
          <PickupTimeAlert />
        </CartProviderV2>
      </AppLayout>
    </ThemeProvider>
  );
};

export default Menu;
