/// <reference types="applepayjs" />
import { useEffect } from 'react';
import { GatewayResponse } from '../types/GatewayResponse';
import { GatewayError } from '../GatewayError';
import fetchApplePaySession from '../../../utils/Payments/CardPointe/fetchApplePaySession';
import cardpointePreauth from '../../../utils/Payments/CardPointe/cardpointePreauth';
import cardpointeTokenize, {
  CardPointeRequest
} from '../../../utils/Payments/CardPointe/cardpointeTokenize';
import { getErrorDialogText } from '../../../utils/Payments/Stripe/errors';

interface ApplePayProps {
  siteId: string;
  amount: string;
  onSuccess: (confirmationId: string) => void;
  onError: (error: GatewayError) => void;
}

const ApplePay = ({ siteId, amount, onSuccess, onError }: ApplePayProps) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://applepay.cdn-apple.com/jsapi/v1/apple-pay-sdk.js';
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const startApplePaySession = () => {
    if (!window.ApplePaySession) {
      console.error('Apple Pay is not supported on this browser.');
      return;
    }

    const request: ApplePayJS.ApplePayPaymentRequest = {
      countryCode: 'US',
      currencyCode: 'USD',
      supportedNetworks: [
        'visa',
        'masterCard',
        'amex',
        'discover',
        'interac',
        'jcb',
        'maestro'
      ],
      merchantCapabilities: ['supports3DS'],
      total: { label: 'Cinchio', amount: amount }
    };

    const session = new window.ApplePaySession(14, request);
    session.begin();

    session.onvalidatemerchant = async (event) => {
      try {
        const merchantSession = await fetchApplePaySession({
          siteId: siteId,
          validationUrl: event.validationURL
        });

        session.completeMerchantValidation(merchantSession.sessionObject);

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        const errorResponse = getErrorDialogText('processing_error');
        onError(
          new GatewayError(
            'declined',
            errorResponse.title,
            errorResponse.description,
            undefined
          )
        );
      }
    };

    session.onpaymentauthorized = async (event) => {
      const paymentRequest = event.payment;

      // Extract required data from the Apple Pay payment token
      const { data, header, signature } = paymentRequest.token.paymentData;
      const {
        ephemeralPublicKey,
        publicKeyHash,
        transactionId,
        applicationData
      } = header;

      let deviceData = `${data}&ectype=apple&ecsig=${signature}&eckey=${ephemeralPublicKey}&ectid=${transactionId}`;

      // Optional fields
      if (applicationData) {
        deviceData += `&echash=${applicationData}`;
      } else {
        deviceData += `&echash=`;
      }
      if (publicKeyHash) {
        deviceData += `&ecpublickeyhash=${publicKeyHash}`;
      }

      const cardPointeRequest: CardPointeRequest = {
        encryptionHandler: 'EC_APPLE_PAY',
        deviceData: deviceData,
        siteId: siteId
      };
      try {
        const cardpointeToken = await cardpointeTokenize(cardPointeRequest);
        const preAuthResponse = await cardpointePreauth({
          cardNotPresent: true,
          siteId: siteId,
          account: cardpointeToken.token,
          amount: amount,
          name:
            paymentRequest.billingContact?.givenName +
            ' ' +
            paymentRequest.billingContact?.familyName,
          expiry: paymentRequest.token.paymentData.header.expirationDate,
          cvv: paymentRequest.token.paymentData.header.cvv,
          postal: paymentRequest.billingContact?.postalCode,
          country: paymentRequest.billingContact?.countryCode
        });

        session.completePayment(ApplePaySession.STATUS_SUCCESS);
        onSuccess(preAuthResponse.retref);

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        session.completePayment(ApplePaySession.STATUS_FAILURE);
        const errorResponse = getErrorDialogText('processing_error');
        onError(
          new GatewayError(
            'declined',
            errorResponse.title,
            errorResponse.description,
            undefined
          )
        );
      }
    };
  };
  return (
    <button
      style={{
        WebkitAppearance: '-apple-pay-button',
        width: '100%',
        height: '48px',
        borderRadius: '4px'
      }}
      onClick={startApplePaySession}
    ></button>
  );
};

export default ApplePay;
