import { FC } from 'react';
import AllergenIcon from '../../../components/AllergenIcon';
import MenuItemDescription from '../../Menu/components/MenuItemDescription';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { MenuItem } from '../../Menu/types';
import FormatPrice from '../../Menu/components/FormatPrice/FormatPrice';
import { useTranslation } from 'react-i18next';
import BadgeUnavailable from '../../../components/Badges/BadgeUnavailable';

interface MenuItemDetailsProps {
  item: MenuItem;
}

const ContentContainer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(3),
  marginLeft: theme.spacing(4),
  marginRight: theme.spacing(4),
  marginBottom: theme.spacing(0),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  color: theme.colors.base.black
}));

const MenuItemPrice = styled(Typography)(({ theme }) => ({
  fontSize: theme.spacing(5),
  fontWeight: 700,
  lineHeight: 1
}));

const SubtitleContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center'
});

const MenuItemCalories = styled(Typography)(({ theme }) => ({
  fontSize: theme.spacing(3.5),
  weight: 400,
  marginTop: '1px'
}));

const InfoDivider = styled('div')(({ theme }) => ({
  fontSize: theme.spacing(5),
  weight: 400,
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2)
}));

const AllergenIconsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  paddingBottom: theme.spacing(1)
}));

const StyledItemPriceAndCalloriesContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(2)
}));

const MenuItemDetails: FC<MenuItemDetailsProps> = (props) => {
  const { t } = useTranslation();
  return (
    <ContentContainer data-testid="menu-item-container">
      <StyledItemPriceAndCalloriesContainer>
        {!props.item.isSuspended ? (
          <MenuItemPrice data-testid="menu-item-price">
            {FormatPrice(props.item.price)}{' '}
          </MenuItemPrice>
        ) : (
          <BadgeUnavailable label={t('restaurant.menu.suspended')} />
        )}

        {props.item.calories > 0 && (
          <InfoDivider>{!props.item.isSuspended && '•'}</InfoDivider>
        )}

        {props.item.calories > 0 && (
          <MenuItemCalories data-testid="menu-item-calories">
            {props.item.calories} {t('restaurant.menu.cal')}
          </MenuItemCalories>
        )}
      </StyledItemPriceAndCalloriesContainer>
      <SubtitleContainer>
        <AllergenIconsContainer>
          {props.item.allergens.map((icon, idx) => {
            return (
              <AllergenIcon key={idx} AllergenSVG={icon} marginRight={-1.6} />
            );
          })}
          {props.item.dietaryOptions.map((icon, idx) => {
            return (
              <AllergenIcon key={idx} AllergenSVG={icon} marginRight={-1.6} />
            );
          })}
        </AllergenIconsContainer>
      </SubtitleContainer>
      <MenuItemDescription description={props.item.description} />
    </ContentContainer>
  );
};

export default MenuItemDetails;
