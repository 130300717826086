import { backendConfig } from '../../../config/appConfig';
import axios from 'axios';

interface OrderDetails {
  TransactionTotal: number;
  OrderNumber: number;
  OrderDescription: 'Dine-In' | 'Takeaway';
}

interface InitializePayload {
  OrderDetails: OrderDetails;
  BillingAddress1: string;
  BillingCity: string;
  BillingCountryCode: number;
  BillingFirstName: string;
  BillingLastName: string;
  BillingPostalCode: string;
  Email: string;
  MobilePhone: string;
  Country: string;
}

const initialize = async (
  siteId: string,
  initializePayload: InitializePayload
) => {
  const initializeUrl = `${backendConfig.apiBaseUrl}/eat/payment/freedomPay/site/${siteId}/initialize`;

  const requestBody = JSON.stringify(initializePayload);

  const axiosConfig = {
    method: 'POST',
    url: initializeUrl,
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': backendConfig.apiKey
    },
    data: requestBody
  };
  try {
    const response = await axios(axiosConfig);
    return response.data;
  } catch (err) {
    throw err;
  }
};

export default initialize;
