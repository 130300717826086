import { v4 } from 'uuid';

const EXPIRATION_INTERVAL = 7200000;

const enum SessionKeys {
  DINING_IN = 'dining-in',
  DISMISS_ALCOHOL = 'dismiss-alcohol',
  CLIENT_SECRET = 'client_secret',
  SITE_CONFIG_SESSION_KEY = 'partner_config',
  SESSION_STORAGE_ID = 'eat-session-storage',
  VERSION = 'version',
  SCROLL_POSITION = 'scroll-position'
}

interface SessionTokenInterface {
  id: string;
  timestamp: number;
}

const isExpired = (token: SessionTokenInterface) => {
  return token.timestamp + EXPIRATION_INTERVAL < Date.now();
};

const saveSession = (token: SessionTokenInterface) => {
  sessionStorage.setItem(SessionKeys.SESSION_STORAGE_ID, JSON.stringify(token));
};

const updateSession = (token: SessionTokenInterface) => {
  token.timestamp = Date.now();
  saveSession(token);
  return token;
};

const fetchSession = (): SessionTokenInterface | null => {
  const session = sessionStorage.getItem(SessionKeys.SESSION_STORAGE_ID);

  if (!session) {
    return null;
  }

  return JSON.parse(session);
};

const createSessionToken = (): SessionTokenInterface => {
  const session = {
    id: v4(),
    timestamp: Date.now()
  };

  saveSession(session);
  return session;
};

const getSession = (): SessionTokenInterface => {
  const session = fetchSession();

  if (!session || isExpired(session)) {
    return createSessionToken();
  }

  return updateSession(session);
};

const resetSession = (): SessionTokenInterface => {
  return createSessionToken();
};

const sessionStart = () => {
  getSession();
};

export {
  getSession,
  resetSession,
  sessionStart,
  EXPIRATION_INTERVAL,
  SessionKeys
};
