import { appConfig } from '../../../config/appConfig';

export const getTestOverrideSite = () => {
  if (
    appConfig.environment.toUpperCase() === 'LOCAL' ||
    appConfig.environment.toUpperCase() === 'DEV'
  ) {
    return true;
  }
  return false;
};
