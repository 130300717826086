import getCalculateObject from './getCalculateObject';
import { fetchOrderCalculations } from '../../services/orderService';
import { CartItem, Tip } from '../../features/Cart/types';

const getCalculations = async (
  restaurantId: string,
  cartItems: CartItem[],
  menuId?: number,
  promoCode?: string,
  tip?: Tip,
  hasRoundUp?: boolean
) => {
  if (!menuId || cartItems.length < 1) {
    return null;
  }

  const cartCalculationObject = getCalculateObject(
    cartItems,
    promoCode ? promoCode : '',
    menuId,
    tip,
    hasRoundUp
  );

  const response = await fetchOrderCalculations(
    restaurantId,
    cartCalculationObject
  );

  return response;
};

export default getCalculations;
