import { backendConfig } from '../../../config/appConfig';
import axios from 'axios';

export interface Country {
  name: string; // Full Name
  alpha2: string; // Short Name
  num3: number; // Country Code
}
const fetchCountries = async (): Promise<Country[]> => {
  const axiosConfig = {
    method: 'GET',
    url: `${backendConfig.apiBaseUrl}/eat/payment/countries`,
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': backendConfig.apiKey
    }
  };
  try {
    const response = await axios(axiosConfig);
    return response.data;
  } catch (err) {
    throw err;
  }
};

export default fetchCountries;
