import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import SelectPaymentMethod from '../SelectPaymentMethod';
import { useEffect, useRef, useState } from 'react';

interface FreedomPayProps {
  transactionTotal: number;
  handleClose: () => void;
}

const Header = styled('div', {
  shouldForwardProp: (prop: string) => prop !== 'scrolled'
})<{ scrolled: boolean }>(({ theme, scrolled }) => ({
  backgroundColor: theme.colors.base.white,
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  position: 'fixed',
  top: 0,
  height: theme.spacing(14),
  marginTop: theme.spacing(14),
  width: '100%',
  zIndex: 10000,
  boxShadow: scrolled ? '0px 1px 4px 0px rgba(0, 0, 0, 0.25)' : 'none',
  transition: 'box-shadow 0.3s ease'
}));

const FreedomPayContainer = styled('div')(({ theme }) => ({
  padding: theme.spacing(4),
  marginBottom: theme.spacing(4),
  overflowY: 'auto'
}));

const FreedomPay = ({ transactionTotal, handleClose }: FreedomPayProps) => {
  const [scrolled, setScrolled] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const handleScroll = () => {
    if (containerRef.current && containerRef.current.scrollTop > 0) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
      return () => {
        container.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);

  return (
    <>
      <Header data-test-id="freedom-pay-header" scrolled={scrolled}>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8
          }}
        >
          <CloseIcon />
        </IconButton>
      </Header>
      <FreedomPayContainer
        data-testid="freedom-pay-container"
        ref={containerRef}
      >
        <SelectPaymentMethod transactionTotal={transactionTotal} />
      </FreedomPayContainer>
    </>
  );
};

export default FreedomPay;
