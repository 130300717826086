import { useState, SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import Accordion, { AccordionProps } from '@mui/material/Accordion';
import AccordionSummary, {
  AccordionSummaryProps
} from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionDetails from '@mui/material/AccordionDetails/AccordionDetails';
import Typography from '@mui/material/Typography';
import { useAnalytics } from '../../hooks/useAnalytics';
import { OrderSummary } from '../../features/Cart/types';
import FormatPrice from '../../features/Menu/components/FormatPrice/FormatPrice';

export interface OrderTotalProps {
  promoCode?: string;
  showTip: boolean;
  tipType?: string | null;
  showRoundUp: boolean;
  cartCalculations: OrderSummary;
}

interface TaxProps {
  tax: number;
  useVAT: boolean;
}

interface TaxAndFeeProps extends TaxProps {
  fee: number;
}

const ItemDivider = styled('div')(({ theme }) => ({
  borderTop: '1px solid',
  borderColor: theme.colors.gray[300],
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1)
}));

const RegText = styled(Typography)(({}) => ({
  display: 'inline-block'
}));

const StyledTipType = styled(Typography)(({ theme }) => ({
  display: 'inline-block',
  textTransform: 'lowercase',
  marginRight: theme.spacing(2),
  ':first-letter': {
    textTransform: 'capitalize'
  }
}));

const BoldText = styled(Typography)(({}) => ({
  fontWeight: 600
}));

const SmallText = styled(Typography)(({ theme }) => ({
  color: theme.colors.gray[500],
  fontSize: theme.spacing(3)
}));

const OrderTotalContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(4)
}));

const SubtotalContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  paddingBottom: theme.spacing(1)
}));

const ItemContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  paddingTop: theme.spacing(2),
  paddingBottom: 0
}));

const StyledRoundUpContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: theme.spacing(2)
}));

const DiscountPriceContainer = styled('div')(({}) => ({
  display: 'flex'
}));

const DiscountTotalText = styled(Typography)(({}) => ({}));

const TaxFeesContainer = styled('div')(({}) => ({}));

const TaxFeesAccordion = styled(Accordion)<AccordionProps>(({ theme }) => ({
  '.MuiButtonBase-root.MuiButtonBase-root': {
    margin: 0,
    padding: 0,
    marginTop: theme.spacing(-1),
    marginBottom: theme.spacing(-1)
  },
  boxShadow: 'none'
}));

const OrderAccordionSummary = styled(AccordionSummary)<AccordionSummaryProps>(
  ({ theme }) => ({
    boxShadow: 'none',
    '.MuiAccordionSummary-expandIconWrapper': {
      color: theme.colors.base.black,
      order: -1
    }
  })
);

const AccordionSummaryContainer = styled('div')(({}) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-start'
}));

const TaxFeeDetails = styled('div')(({}) => ({}));

const TaxFeeItem = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1)
}));

const ServiceFee = styled('p')(({ theme }) => ({
  fontSize: theme.spacing(3.5),
  marginTop: 0,
  marginBottom: 0,
  paddingBottom: theme.spacing(1)
}));

const TaxAndFee = ({ tax, useVAT, fee }: TaxAndFeeProps) => {
  const { t } = useTranslation();
  const [rotateExpandIcon, setRotateExpandIcon] = useState(false);
  const logEvent = useAnalytics();

  return (
    <TaxFeesAccordion
      disableGutters
      onChange={(event: SyntheticEvent, expanded: boolean) => {
        if (expanded) {
          logEvent('tax_fee_selection');
        }
      }}
    >
      <OrderAccordionSummary
        aria-controls="order-content"
        id="order-header"
        data-testid="tax-fees-accordion-summary"
      >
        <AccordionSummaryContainer
          onClick={() => setRotateExpandIcon(!rotateExpandIcon)}
        >
          <RegText data-testid="tax-&-fees">
            {t('restaurant.cart.taxAndFees')}
          </RegText>
          <ExpandMoreIcon
            style={{
              transform: rotateExpandIcon ? 'rotate(180deg)' : 'rotate(0deg)'
            }}
          />
          <RegText sx={{ marginLeft: 'auto' }} data-testid="total-tax-and-fees">
            {FormatPrice(tax + fee)}
          </RegText>
        </AccordionSummaryContainer>
      </OrderAccordionSummary>
      <AccordionDetails sx={{ marginTop: -1, padding: 0 }}>
        <TaxFeeDetails>
          <TaxFeeItem>
            <RegText data-testid="tax">
              {useVAT
                ? t('restaurant.checkout.vat')
                : t('restaurant.checkout.tax')}
            </RegText>
            <RegText data-testid="total-tax">{FormatPrice(tax)}</RegText>
          </TaxFeeItem>
          <TaxFeeItem>
            <RegText data-testid="service-fee-description">
              {t('restaurant.checkout.serviceFee')}
            </RegText>
            <RegText data-testid="service-fee-amount">
              {FormatPrice(fee)}
            </RegText>
          </TaxFeeItem>
          <TaxFeeItem>
            <ServiceFee data-testid="service-fee-info">
              {t('restaurant.checkout.serviceFeeInfo')}
            </ServiceFee>
          </TaxFeeItem>
        </TaxFeeDetails>
      </AccordionDetails>
    </TaxFeesAccordion>
  );
};

const TaxesOnly = ({ tax, useVAT }: TaxProps) => {
  const { t } = useTranslation();

  return (
    <TaxFeeDetails style={{ paddingBottom: 4 }}>
      <TaxFeeItem>
        <RegText data-testid="tax">
          {useVAT ? t('restaurant.cart.vat') : t('restaurant.cart.tax')}
        </RegText>
        <RegText data-testid="total-tax">{FormatPrice(tax)}</RegText>
      </TaxFeeItem>
    </TaxFeeDetails>
  );
};

const FeeOnly = ({ fee }: { fee: number }) => {
  const { t } = useTranslation();

  return (
    <TaxFeeDetails style={{ paddingBottom: 4 }}>
      <TaxFeeItem>
        <RegText data-testid="service-fee-description">
          {t('restaurant.checkout.serviceFee')}
        </RegText>
        <RegText data-testid="service-fee-amount">{FormatPrice(fee)}</RegText>
      </TaxFeeItem>
      <TaxFeeItem>
        <ServiceFee data-testid="service-fee-info">
          {t('restaurant.checkout.serviceFeeInfo')}
        </ServiceFee>
      </TaxFeeItem>
    </TaxFeeDetails>
  );
};

const OrderTotal = ({
  promoCode,
  showTip,
  tipType,
  showRoundUp,
  cartCalculations
}: OrderTotalProps) => {
  const { t } = useTranslation();
  const useVAT = cartCalculations.taxLabel === 'VAT';

  return (
    <OrderTotalContainer data-testid="order-total-container">
      <ItemContainer style={{ paddingBottom: 4 }}>
        <RegText data-testid="location-label">
          {t('restaurant.cart.dineIn')}
        </RegText>
      </ItemContainer>
      <SubtotalContainer>
        <BoldText data-testid="cart-subtotal-label">
          {t('restaurant.cart.subtotal')}
        </BoldText>
        <BoldText data-testid="cart-subtotal">
          {FormatPrice(cartCalculations.subtotal)}
        </BoldText>
      </SubtotalContainer>
      {cartCalculations.discount > 0 && (
        <>
          <ItemContainer>
            <RegText data-testid="order-total-discounts">
              {t('restaurant.cart.promoCode')}:
            </RegText>
            <DiscountPriceContainer>
              <DiscountTotalText data-testid="cart-discount-total">
                -{FormatPrice(cartCalculations.discount)}
              </DiscountTotalText>
            </DiscountPriceContainer>
          </ItemContainer>
          {promoCode && (
            <ItemContainer style={{ paddingBottom: 4 }}>
              <SmallText data-testid="order-total-member-discount-code">
                {promoCode.toUpperCase()}
              </SmallText>
              <DiscountPriceContainer>
                <SmallText data-testid="cart-discount-code-amount">
                  -{FormatPrice(cartCalculations.discount)}
                </SmallText>
              </DiscountPriceContainer>
            </ItemContainer>
          )}
        </>
      )}
      {!useVAT && (
        <TaxFeesContainer data-testid="tax-&-fees-container">
          {cartCalculations.fee > 0 ? (
            <TaxAndFee
              tax={cartCalculations.tax}
              fee={cartCalculations.fee}
              useVAT={useVAT}
            />
          ) : (
            <TaxesOnly tax={cartCalculations.tax} useVAT={useVAT} />
          )}
        </TaxFeesContainer>
      )}
      {useVAT && cartCalculations.fee > 0 && (
        <FeeOnly fee={cartCalculations.fee} />
      )}
      {showTip && cartCalculations.tip > 0 ? (
        <StyledRoundUpContainer>
          <RegText data-testid="tip-description">
            {t('restaurant.checkout.tip')}
          </RegText>
          <div>
            {tipType && (
              <StyledTipType data-testid="tip-type">({tipType})</StyledTipType>
            )}
            <RegText data-testid="tip-amount">
              {FormatPrice(cartCalculations.tip)}
            </RegText>
          </div>
        </StyledRoundUpContainer>
      ) : null}
      {cartCalculations.previewRoundUp > 0 && showRoundUp && (
        <StyledRoundUpContainer>
          <RegText data-testid="round-up-description">
            {t('restaurant.checkout.roundUpDonation')}
          </RegText>
          <RegText data-testid="round-up-amount">
            {FormatPrice(cartCalculations.previewRoundUp)}
          </RegText>
        </StyledRoundUpContainer>
      )}
      <ItemDivider />
      <ItemContainer>
        <BoldText data-testid="total">{t('restaurant.cart.total')}</BoldText>
        <BoldText data-testid="total-price">
          {FormatPrice(cartCalculations.total)}
        </BoldText>
      </ItemContainer>
      {useVAT && (
        <TaxFeesContainer data-testid="tax-&-fees-container">
          <TaxesOnly tax={cartCalculations.tax} useVAT={useVAT} />
        </TaxFeesContainer>
      )}
    </OrderTotalContainer>
  );
};

export default OrderTotal;
