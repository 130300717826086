import { CartItem, Tip } from '../../features/Cart/types';
import setCartModifiersForProcess from './setCartModsForProcess';

export default function getCalculateObject(
  cartItems: CartItem[],
  promoCode: string | null,
  menuId: number,
  tip?: Tip,
  hasRoundUp?: boolean
) {
  const mappedCartItems = cartItems.map((item, index) => ({
    productId: item.productId,
    clientItemId: String(index),
    modifiers: setCartModifiersForProcess(item.modifiers),
    quantity: item.quantity
  }));

  return {
    cart: mappedCartItems,
    discount: promoCode && promoCode !== '' ? promoCode : null,
    tip: tip ? tip : {},
    hasRoundUp: hasRoundUp ? hasRoundUp : false,
    menuId: menuId,
    platform: 'web',
    orderType: 'dineIn' // update dynamic later
  };
}
