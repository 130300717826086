import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import Button, { ButtonProps } from '@mui/material/Button';
import Typography, { TypographyProps } from '@mui/material/Typography';
import { Link } from 'react-router-dom';

interface ContinueToPaymentBtnProps {
  handlePress: () => void;
}

const ContinueToPaymentContainer = styled('div')(({ theme }) => ({
  backgroundColor: theme.colors.base.white,
  borderTop: `1px solid ${theme.colors.gray[300]}`,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'fixed',
  bottom: 0,
  height: theme.spacing(28),
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4),
  width: '100%',
  zIndex: 1300,
  margin: 'auto',
  maxWidth: theme.breakpoints.values.lg
}));

const ContinueToPaymentButton = styled(Button)<ButtonProps>(({ theme }) => ({
  borderRadius: theme.spacing(2),
  backgroundColor: theme.colors.partner.button.primary.background,
  '&:hover': {
    backgroundColor: theme.colors.partner.button.primary.background
  },
  boxShadow: 'none',
  color: theme.colors.partner.button.primary.font,
  fontSize: theme.spacing(4),
  height: theme.spacing(12),
  marginBottom: theme.spacing(2),
  width: '100%'
}));

const PrivacyCopy = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.colors.gray[500],
  fontSize: theme.spacing(3)
}));

const ContinueToPayment = ({ handlePress }: ContinueToPaymentBtnProps) => {
  const { t } = useTranslation();

  return (
    <ContinueToPaymentContainer>
      <ContinueToPaymentButton
        variant="contained"
        disableRipple
        onClick={handlePress}
        data-testid="checkout-continue-to-payment"
      >
        {t('restaurant.checkout.continueToPayment')}
      </ContinueToPaymentButton>
      <PrivacyCopy>
        {t('restaurant.checkout.privacyAgree')}
        <Link
          to={'https://cinchio.com/privacy-policy'}
          target="_blank"
          style={{ color: '#1a1a1a' }}
        >
          {t('restaurant.checkout.privacyPolicy')}
        </Link>
      </PrivacyCopy>
    </ContinueToPaymentContainer>
  );
};

export default ContinueToPayment;
