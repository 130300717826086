import { useLocation } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import App from './App';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { SiteConfigProvider } from './contexts/SiteConfig/SiteConfigContext';
import { setupTheme } from './utils/Theme/setupTheme';
import { useEffect, useState } from 'react';
import { getSiteConfig } from './utils/API/getSiteConfig';
import NotFoundError from './features/NotFoundError';
import * as i18n from 'i18next';
import logger, { LoggerEvent } from './services/logger';
import getMatchedParams from './utils/Routing/getMatchedParams';
import { emptyConfig } from './models/emptyConfig';

const AppWrapper = () => {
  const [siteConfig, setSiteConfig] = useState(emptyConfig);
  const [error, setError] = useState(false);
  const { storeId, siteId } = getMatchedParams();
  const location = useLocation();

  useEffect(() => {
    const siteCode = siteId ? siteId : storeId ? storeId.slice(0, -2) : '';

    if (
      siteCode?.toLowerCase() === siteConfig.partnerConfig.shortCode.toLowerCase()
    ) {
      setError(false);
      return;
    }

    setError(false);
    setSiteConfig(emptyConfig);

    getSiteConfig(siteCode.toLowerCase())
      .then((newSiteConfig) => {
        setSiteConfig(newSiteConfig);
      })
      .catch(() => {
        if (siteId) {
          logger.error(LoggerEvent.APP_ERROR, `Site not found - ${siteId}`);
        } else {
          logger.error(LoggerEvent.APP_ERROR, `Store not found - ${storeId}`);
        }

        setError(true);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteId, storeId, location]);

  if (error && siteId) {
    return <NotFoundError text={i18n.t('error.siteNotFound')} />;
  }

  if (error) {
    return <NotFoundError text={i18n.t('error.storeNotFound')} />;
  }

  // Paths that will never have a site id attached
  // This is used to prevent theme flickering
  // and unintended re-renders
  if (
    location.pathname !== '/' &&
    location.pathname !== '/version' &&
    !siteConfig.partnerConfig.partnerId
  ) {
    return null;
  }
  const theme = setupTheme(siteConfig.colorConfig);

  return (
    <SiteConfigProvider siteConfig={siteConfig}>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <App />
        <ReactQueryDevtools position="bottom-right" />
      </ThemeProvider>
    </SiteConfigProvider>
  );
};

export default AppWrapper;
